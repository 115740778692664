import {
  CreateAction,
  Action,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  AnnotationDisplayCondition,
  AnnotationResults,
  AnnotationSetDetailActionType,
  CurrentAnnotationSetDetail,
  TrainingData,
  TrainingDataDisplayCondition,
} from './types'
import { ToastInfo } from 'state/utils'
import { CanvasInfoDisplayCondition } from 'views/utils/types'

export type AnnotationSetDetailAction = ActionsUnion<
  typeof annotationSetDetailActions
>

export const annotationSetDetailActions = {
  setCurrentAnnotationSetDetail: (
    currentAnnotationSetDetail: CurrentAnnotationSetDetail
  ): ActionWithPayload<
    'SET_CURRENT_ANNOTATION_SET_DETAIL',
    { currentAnnotationSetDetail: CurrentAnnotationSetDetail }
  > =>
    CreateAction(
      AnnotationSetDetailActionType.SET_CURRENT_ANNOTATION_SET_DETAIL,
      {
        currentAnnotationSetDetail,
      }
    ),

  setTrainingDataList: (trainingDataList: {
    [fileName: string]: TrainingData
  }): ActionWithPayload<
    'SET_TRAINING_DATA_LIST',
    { trainingDataList: { [fileName: string]: TrainingData } }
  > =>
    CreateAction(AnnotationSetDetailActionType.SET_TRAINING_DATA_LIST, {
      trainingDataList,
    }),

  setAnnotationSetState: (
    annotationSetState:
      | 'BeforeLoading'
      | 'Loaded'
      | 'NotFoundProcessed'
      | 'Failed'
  ): ActionWithPayload<
    'SET_ANNOTATION_SET_STATE',
    {
      annotationSetState:
        | 'BeforeLoading'
        | 'Loaded'
        | 'NotFoundProcessed'
        | 'Failed'
    }
  > =>
    CreateAction(AnnotationSetDetailActionType.SET_ANNOTATION_SET_STATE, {
      annotationSetState,
    }),

  setInProgress: (
    isInProgressForGettingAnnotationSetDetail: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_ANNOTATION_SET_DETAIL',
    { isInProgressForGettingAnnotationSetDetail: boolean }
  > =>
    CreateAction(
      AnnotationSetDetailActionType.SET_IN_PROGRESS_FOR_ANNOTATION_SET_DETAIL,
      {
        isInProgressForGettingAnnotationSetDetail,
      }
    ),

  setInProgressForGettingAnnotationFile: (
    isInProgressForGettingAnnotationFile: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_GETTING_ANNOTATION_FILE',
    { isInProgressForGettingAnnotationFile: boolean }
  > =>
    CreateAction(
      AnnotationSetDetailActionType.SET_IN_PROGRESS_FOR_GETTING_ANNOTATION_FILE,
      {
        isInProgressForGettingAnnotationFile,
      }
    ),

  setInProgressForGeneratingAnnotationResults: (
    isInProgressForGeneratingAnnotationResults: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_GENERATING_ANNOTATION_RESULTS',
    { isInProgressForGeneratingAnnotationResults: boolean }
  > =>
    CreateAction(
      AnnotationSetDetailActionType.SET_IN_PROGRESS_FOR_GENERATING_ANNOTATION_RESULTS,
      {
        isInProgressForGeneratingAnnotationResults,
      }
    ),

  setSelectedImageFileName: (
    selectedImageFileName: string
  ): ActionWithPayload<
    'SET_SELECTED_IMAGE_FILE_NAME',
    { selectedImageFileName: string }
  > =>
    CreateAction(AnnotationSetDetailActionType.SET_SELECTED_IMAGE_FILE_NAME, {
      selectedImageFileName,
    }),

  setAnnotationResults: (annotationResults: {
    [key: string]: AnnotationResults[]
  }): ActionWithPayload<
    'SET_ANNOTATION_RESULTS',
    {
      annotationResults: {
        [key: string]: AnnotationResults[]
      }
    }
  > =>
    CreateAction(AnnotationSetDetailActionType.SET_ANNOTATION_RESULTS, {
      annotationResults,
    }),

  setToastInfo: (
    toastInfo?: ToastInfo
  ): ActionWithPayload<
    'SET_TOAST_INFO',
    {
      toastInfo?: ToastInfo
    }
  > =>
    CreateAction(AnnotationSetDetailActionType.SET_TOAST_INFO, {
      toastInfo,
    }),

  setAnnotationDisplayCondition: (
    annotationDisplayCondition: AnnotationDisplayCondition
  ): ActionWithPayload<
    'SET_ANNOTATION_DISPLAY_CONDITION',
    { annotationDisplayCondition: AnnotationDisplayCondition }
  > =>
    CreateAction(
      AnnotationSetDetailActionType.SET_ANNOTATION_DISPLAY_CONDITION,
      {
        annotationDisplayCondition,
      }
    ),

  setCanvasInfoDisplayCondition: (
    canvasInfoDisplayCondition: CanvasInfoDisplayCondition
  ): ActionWithPayload<
    'SET_CANVAS_INFO_DISPLAY_CONDITION',
    { canvasInfoDisplayCondition: CanvasInfoDisplayCondition }
  > =>
    CreateAction(
      AnnotationSetDetailActionType.SET_CANVAS_INFO_DISPLAY_CONDITION,
      {
        canvasInfoDisplayCondition,
      }
    ),

  setTrainingDataDisplayCondition: (
    trainingDataDisplayCondition: TrainingDataDisplayCondition
  ): ActionWithPayload<
    'SET_TRAINING_DATA_DISPLAY_CONDITION',
    { trainingDataDisplayCondition: TrainingDataDisplayCondition }
  > =>
    CreateAction(
      AnnotationSetDetailActionType.SET_TRAINING_DATA_DISPLAY_CONDITION,
      {
        trainingDataDisplayCondition,
      }
    ),

  clearAnnotationSetDetail: (): Action<'CLEAR_ANNOTATION_SET_DETAIL'> =>
    CreateAction(AnnotationSetDetailActionType.CLEAR_ANNOTATION_SET_DETAIL),
}
