import { AnnotationSetDetail, AnnotationSetDetailActionType } from './types'
import { AnnotationSetDetailAction } from './actions'

const initialState: AnnotationSetDetail = {
  domainData: {
    currentAnnotationSetDetail: {
      algorithm: {
        algorithmId: '',
        metadata: { name: { en: '', ja: '' } },
      },
      annotationFileName: '',
      annotationResults: {},
      annotationSetId: '',
      annotationSetKind: 'Test',
      selectedTrainingDataFileName: '',
      trainingDataList: {},
      conditions: undefined,
      annotationFileData: {
        images: [],
        annotations: [],
        categories: [],
      },
      trainingImageCount: 0,
    },
    annotationsDisplayCondition: {
      bbox: false,
      label: false,
      mask: false,
      selectedIds: [],
    },
    canvasInfoDisplayCondition: {
      totalCount: 10,
      displayCount: '5',
      isDisplayed: true,
    },
    trainingDataDisplayCondition: {
      classNames: [],
    },
  },
  appState: {
    annotationSetDetailState: {
      annotationFileDownloadSubState: 'BeforeLoading',
      annotationSetState: 'BeforeLoading',
    },
    isInProgressForGeneratingAnnotationResults: false,
    isInProgressForGettingAnnotationFile: false,
    isInProgressForGettingAnnotationSetDetail: false,
    toastInfo: undefined,
  },
}

export const AnnotationSetDetailReducer = (
  state: AnnotationSetDetail = initialState,
  action: AnnotationSetDetailAction
): AnnotationSetDetail => {
  switch (action.type) {
    case AnnotationSetDetailActionType.SET_ANNOTATION_SET_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          annotationSetDetailState: {
            ...state.appState.annotationSetDetailState,
            annotationSetState: action.payload.annotationSetState,
          },
        },
      }
    case AnnotationSetDetailActionType.SET_CURRENT_ANNOTATION_SET_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentAnnotationSetDetail: action.payload.currentAnnotationSetDetail,
        },
      }
    case AnnotationSetDetailActionType.SET_TRAINING_DATA_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentAnnotationSetDetail: {
            ...state.domainData.currentAnnotationSetDetail,
            trainingDataList: action.payload.trainingDataList,
          },
        },
      }
    case AnnotationSetDetailActionType.SET_IN_PROGRESS_FOR_ANNOTATION_SET_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForGettingAnnotationSetDetail:
            action.payload.isInProgressForGettingAnnotationSetDetail,
        },
      }
    case AnnotationSetDetailActionType.SET_IN_PROGRESS_FOR_GENERATING_ANNOTATION_RESULTS:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForGeneratingAnnotationResults:
            action.payload.isInProgressForGeneratingAnnotationResults,
        },
      }
    case AnnotationSetDetailActionType.SET_IN_PROGRESS_FOR_GETTING_ANNOTATION_FILE:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForGettingAnnotationFile:
            action.payload.isInProgressForGettingAnnotationFile,
        },
      }
    case AnnotationSetDetailActionType.SET_SELECTED_IMAGE_FILE_NAME:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentAnnotationSetDetail: {
            ...state.domainData.currentAnnotationSetDetail,
            selectedTrainingDataFileName: action.payload.selectedImageFileName,
          },
        },
      }
    case AnnotationSetDetailActionType.SET_ANNOTATION_RESULTS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentAnnotationSetDetail: {
            ...state.domainData.currentAnnotationSetDetail,
            annotationResults: action.payload.annotationResults,
          },
        },
      }
    case AnnotationSetDetailActionType.SET_TOAST_INFO:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case AnnotationSetDetailActionType.SET_ANNOTATION_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          annotationsDisplayCondition:
            action.payload.annotationDisplayCondition,
        },
      }
    case AnnotationSetDetailActionType.SET_CANVAS_INFO_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          canvasInfoDisplayCondition: action.payload.canvasInfoDisplayCondition,
        },
      }
    case AnnotationSetDetailActionType.SET_TRAINING_DATA_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainingDataDisplayCondition:
            action.payload.trainingDataDisplayCondition,
        },
      }
    case AnnotationSetDetailActionType.CLEAR_ANNOTATION_SET_DETAIL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
