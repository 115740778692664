import { HttpsCallableResult } from '@firebase/functions'
import { ToastInfo } from 'state/utils'
import { I18nString } from 'types/StateTypes'
import { CanvasInfoDisplayCondition } from 'views/utils/types'

export const AnnotationSetDetailActionType = {
  SET_CURRENT_ANNOTATION_SET_DETAIL: 'SET_CURRENT_ANNOTATION_SET_DETAIL',
  SET_IN_PROGRESS_FOR_ANNOTATION_SET_DETAIL:
    'SET_IN_PROGRESS_FOR_ANNOTATION_SET_DETAIL',
  SET_ANNOTATION_SET_STATE: 'SET_ANNOTATION_SET_STATE',
  SET_SELECTED_IMAGE_FILE_NAME: 'SET_SELECTED_IMAGE_FILE_NAME',
  SET_ANNOTATION_DISPLAY_CONDITION: 'SET_ANNOTATION_DISPLAY_CONDITION',
  SET_TRAINING_DATA_DISPLAY_CONDITION: 'SET_TRAINING_DATA_DISPLAY_CONDITION',
  CLEAR_ANNOTATION_SET_DETAIL: 'CLEAR_ANNOTATION_SET_DETAIL',
  SET_ANNOTATION_RESULTS: 'SET_ANNOTATION_RESULTS',
  SET_IN_PROGRESS_FOR_GETTING_ANNOTATION_FILE:
    'SET_IN_PROGRESS_FOR_GETTING_ANNOTATION_FILE',
  SET_IN_PROGRESS_FOR_GENERATING_ANNOTATION_RESULTS:
    'SET_IN_PROGRESS_FOR_GENERATING_ANNOTATION_RESULTS',
  SET_TOAST_INFO: 'SET_TOAST_INFO',
  SET_TRAINING_DATA_LIST: 'SET_TRAINING_DATA_LIST',
  SET_CANVAS_INFO_DISPLAY_CONDITION: 'SET_CANVAS_INFO_DISPLAY_CONDITION',
} as const

interface GetSignedUrlsResult extends HttpsCallableResult {
  readonly data: {
    [id: string]: string
  }
}

export type GetSignedUrlsFunctionType = (args: {
  fileInfoList: { id: string; fileName: string }[]
}) => Promise<GetSignedUrlsResult>

/** アルゴリズム */
export interface Algorithm {
  /** アルゴリズムID */
  algorithmId: string
  /** メタデータ */
  metadata: {
    /** アルゴリズム名 */
    name: I18nString
  }
}

/** 画像グループに属する学習用画像 */
export interface TrainingData {
  /** 学習用画像のID */
  id: string
  /** 学習用画像のURL（サムネイル） */
  thumbnailUrl: string
  /** 学習用画像のURL（オリジナル） */
  processedUrl: string
}

/** ボックス, ラベル、マスク情報（学習画像のファイル名キーとして保持） */
export interface AnnotationResults {
  /** アノテーションファイル内の image_id */
  imageId: number | string
  /** 座標（[左上のx, 左上のy, width, height]） */
  bbox: number[]
  /** ボックスのサイズ */
  area: number
  /** ラベル情報 */
  label: {
    /** ラベルのID */
    id: string
    /** 表示名 */
    name: string
  }
  /** セグメンテーション情報 */
  segmentation: RleStringSegmentation | RleArrayNumberSegmentation
}

export interface AnnotationFileData {
  images: {
    id: number | string
    width: number
    height: number
    file_name: string
  }[]
  annotations: {
    id: number
    image_id: number | string
    category_id: number
    segmentation: { size: number[]; counts: string | number[] } | number[][]
    bbox: number[]
    area: number
    iscrowd: number | boolean
  }[]
  categories: {
    id: number
    name: string
  }[]
  extensions?: {
    classes?: {
      class_name: string
      image_id_list: string[]
    }[]
  }
}

/** アノテーションセットの種別 */
export type AnnotationSetKind = 'Train' | 'Test' | 'Valid'
/** 'Train'の場合の種類 */
export type SegmentationType = 'RI' | 'SI' | 'Background'

/** アノテーションセットの詳細 */
export interface CurrentAnnotationSetDetail {
  /** アノテーションファイル名 */
  annotationFileName: string
  /** アノテーションセットID */
  annotationSetId: string
  /** アノテーションセットの種別 */
  annotationSetKind: AnnotationSetKind
  /** アノテーションセットの種別の付加情報 */
  conditions?: {
    /** 'Train'の場合の種類 */
    trainKind: SegmentationType
  }
  /** アルゴリズム */
  algorithm: Algorithm
  /** 画像グループに属する学習用画像（学習画像のファイル名キーとして保持） */
  trainingDataList: { [fileName: string]: TrainingData }
  /** 選択中の学習画像のファイル名 */
  selectedTrainingDataFileName: string
  /** ボックス, ラベル、マスク情報（学習画像のファイル名キーとして保持） */
  annotationResults: { [key: string]: AnnotationResults[] }
  /** アノテーションファイルのパース結果（必要な分のみ保持） */
  annotationFileData?: AnnotationFileData
  /** image_id（アノテーションの学習画像のID）とfile_name（学習画像のファイル名）の紐付けを保持 */
  imageIdFileName?: { [imageId: string]: string }
  /** 学習画像の枚数 */
  trainingImageCount: number
}

/** アノテーション情報の表示条件 */
export interface AnnotationDisplayCondition {
  /** マスク情報を表示するか */
  mask: boolean
  /** bbox情報を表示するか */
  bbox: boolean
  /** label情婦を表示するか */
  label: boolean
  /** 情報を表示する対象のラベルID */
  selectedIds: string[]
}

/** 学習画像情報の表示条件 */
export interface TrainingDataDisplayCondition {
  /** 表示するクラス名 */
  classNames: string[]
}

export interface AnnotationSetDetailDomainData {
  /** アノテーションセットの詳細 */
  currentAnnotationSetDetail: CurrentAnnotationSetDetail
  /** アノテーション情報の表示条件 */
  annotationsDisplayCondition: AnnotationDisplayCondition
  /** キャンバスの表示条件 */
  canvasInfoDisplayCondition: CanvasInfoDisplayCondition
  /** 学習画像の表示条件 */
  trainingDataDisplayCondition: TrainingDataDisplayCondition
}

export interface AnnotationSetDetailState {
  /** アノテーションセット取得状況 */
  annotationSetState:
    | 'BeforeLoading'
    | 'Loaded'
    | 'NotFoundProcessed'
    | 'Failed'
  /** アノテーションファイルダウンロード状況 */
  annotationFileDownloadSubState:
    | 'BeforeLoading'
    | 'Loaded'
    | 'NotFoundProcessed'
    | 'Failed'
}

export interface AnnotationSetDetailAppState {
  /** アノテーションセット詳細情報取得中フラグ */
  isInProgressForGettingAnnotationSetDetail: boolean
  /** アノテーションファイルDL中フラグ */
  isInProgressForGettingAnnotationFile: boolean
  /** 学習画像に対するボックス, ラベル、マスク情報の生成処理中フラグ */
  isInProgressForGeneratingAnnotationResults: boolean
  /** アノテーションセット画面のステータス */
  annotationSetDetailState: AnnotationSetDetailState
  /** トースト表示情報 */
  toastInfo?: ToastInfo
}

export interface AnnotationSetDetail {
  domainData: AnnotationSetDetailDomainData
  appState: AnnotationSetDetailAppState
}

export type RleStringMask = {
  size: number[]
  counts: string
}
export type RleArrayNumberMask = {
  size: number[]
  counts: number[]
}
export type PolygonSegmentation = number[][]
export type RleStringSegmentation = {
  type: 'RLEString'
  mask: RleStringMask
}
export type RleArrayNumberSegmentation = {
  type: 'RLEArrayNumber'
  mask: RleArrayNumberMask
}

export type GetAnnotationFileDataResponse = {
  info: {
    contributor: string
    date_create: string
    description: string
    url: string
    version: string
    year: number
  }[]
  licenses: {
    id: number
    name: string
    url: string
  }[]
  images: {
    coco_url: string | null
    date_capture: string | null
    file_name: string
    flickr_url: string | null
    height: number
    id: string
    license: number
    width: number
  }[]
  annotations: {
    area: number
    bbox: number[]
    category_id: number
    id: number
    image_id: number
    iscrowd: boolean
    segmentation: { size: number[]; counts: string | number[] } | number[][]
  }[]
  categories: {
    id: number
    name: string
    supercategory: string
  }[]
  extensions?: {
    classes?: {
      class_name: string
      image_id_list: string[]
    }[]
  }
}
