import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import clsx from 'clsx'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { State } from 'state/store'
import { makeStyles } from 'tss-react/mui'
import {
  BreadcrumbsComponent,
  CopyableLabel,
  DatasetIcon,
  GlobalLoading,
  InferenceResultsViewer,
  OnsiteInferenceResultWithImage,
  ThumbnailInfo,
  ThumbnailSlider,
  Toast,
  showToast,
} from 'views/components'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import HandymanIcon from '@mui/icons-material/Handyman'
import { useTheme } from '@mui/material/styles'
import {
  AnnotationDisplayCondition,
  AnnotationResults,
  AnnotationSetDetailAction,
  AnnotationSetDetailOperations,
  TrainingDataDisplayCondition,
  annotationSetDetailActions,
} from 'state/ducks/annotationSetDetail'
import { isUndefined } from 'utils/typeguard'
import { CanvasInfo, CanvasInfoDisplayCondition } from 'views/utils/types'
import {
  getCheckedAnnotationData,
  setCheckedAnnotationData,
} from 'views/containers/utils/localStorage'
import { doc, getDoc } from 'firebase/firestore'
import { getDatasetQueryCollection } from 'state/firebase'
import { createColor } from 'utils/colors'
import { handleResourceNotFound } from 'views/containers/utils'
import { InferenceResultDisplayCondition } from 'state/ducks/inferenceDetail'

const CONTENT_HEIGHT = 480

/** アノテーションファイルが存在しなかった場合のタイトル */
const TITLE_FOR_NOT_FOUND_ANNOTATION_FILE = '推論用画像'

const mapStateToProps = (state: State) => ({
  ...state.pages.annotationSetDetailState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, AnnotationSetDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** データセット詳細取得 */
  getAnnotationSetDetail: (datasetId: string, annotationSetId: string) =>
    dispatch(
      AnnotationSetDetailOperations.getAnnotationSetDetail(
        datasetId,
        annotationSetId
      )
    ),
  setSelectedImage: (imageId: string) =>
    dispatch(AnnotationSetDetailOperations.setSelectedImage(imageId)),
  setAnnotationDisplayCondition: (
    annotationDisplayCondition: AnnotationDisplayCondition
  ) =>
    dispatch(
      annotationSetDetailActions.setAnnotationDisplayCondition(
        annotationDisplayCondition
      )
    ),
  clearAnnotationSetDetail: () =>
    dispatch(annotationSetDetailActions.clearAnnotationSetDetail()),
  deleteToastInfo: () =>
    dispatch(annotationSetDetailActions.setToastInfo(undefined)),
  setCanvasInfoDisplayCondition: (
    canvasInfoDisplayCondition: CanvasInfoDisplayCondition
  ) =>
    dispatch(
      annotationSetDetailActions.setCanvasInfoDisplayCondition(
        canvasInfoDisplayCondition
      )
    ),
  setTrainingDataDisplayCondition: (
    trainingDataDisplayCondition: TrainingDataDisplayCondition
  ) =>
    dispatch(
      annotationSetDetailActions.setTrainingDataDisplayCondition(
        trainingDataDisplayCondition
      )
    ),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  header: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  mt2Box: {
    marginTop: '16px',
  },
  noteTabButton: {
    backgroundColor: '#D9E5FF',
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  annotationSetLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  slider: {
    width: '30%',
    '& > .MuiSlider-track': {
      border: 'none',
    },
  },
  media: {
    height: '88px',
    width: '157px',
    objectFit: 'contain',
  },
  checkbox: {
    height: '30px',
    width: '30px',
  },
  fileDataLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  detailContent: {
    height: CONTENT_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    overflowY: 'scroll',
    '& > div': {
      minHeight: 137,
    },
  },
  textField: {
    padding: 0,
  },
}))

const DatasetDetailAnnotation: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const globalTheme = useTheme()
  const history = useHistory()
  const {
    trainingImageData,
    annotationFileName,
    algorithmDisplayName,
    breadcrumbsAnnotationFileName,
    canvasInfos,
    checkedAnnotationDataState,
    datasetName,
    detailCanvasInfos,
    fileDataLoading,
    nowTab,
    selectedImageId,
    thumbnailInfos,
    inferenceResultDisplayCondition,
    getChecked,
    onClickThumbnail,
    onChangeCanvasInfoDisplayCondition,
    setDatasetName,
    setNowTab,
    updateCheckedAnnotationData,
  } = useDatasetDetailAnnotation(props)

  const datasetId = (props.match.params as { [key: string]: string })['id']
  const annotationSetId = (props.match.params as { [key: string]: string })[
    'annotationSetId'
  ]

  useEffect(() => {
    return () => {
      props.clearAnnotationSetDetail()
    }
  }, [])

  /** 対象のデータがない場合、データが不正の場合はhomeに戻る */
  useEffect(() => {
    handleResourceNotFound(
      props.appState.annotationSetDetailState.annotationSetState,
      history
    )
  }, [props.appState.annotationSetDetailState.annotationSetState])

  useEffect(() => {
    const getDatasetName = async () => {
      const datasetData = (
        await getDoc(
          doc(
            getDatasetQueryCollection(props.auth.customClaims.userGroupId),
            datasetId
          )
        )
      ).data()
      setDatasetName((datasetData?.['dataset-name'] as string) ?? '')
    }
    getDatasetName()
  }, [datasetId, props.auth.customClaims.userGroupId])

  useEffect(() => {
    if (datasetId !== '' && annotationSetId !== '') {
      props.getAnnotationSetDetail(datasetId, annotationSetId)
    }
  }, [datasetId, annotationSetId])

  const tabItems: TabItems[] = [
    // 実行情報コンテンツ
    {
      label: 'アノテーション',
      displayInfo: (
        <Box component={Paper}>
          <Box p={'48px 32px 32px 48px'} height={CONTENT_HEIGHT}>
            {Object.keys(
              props.domainData.currentAnnotationSetDetail.trainingDataList
            ).length > 0 && (
              <InferenceResultsViewer
                control={false}
                loading={
                  props.appState.isInProgressForGeneratingAnnotationResults
                }
                selectedImageId={selectedImageId}
                imageData={trainingImageData}
                canvasInfos={canvasInfos}
                inferenceResultDisplayCondition={
                  inferenceResultDisplayCondition
                }
                classNames={props.domainData.currentAnnotationSetDetail.annotationFileData?.extensions?.classes?.map(
                  (c) => {
                    return c.class_name
                  }
                )}
                selectedClassNames={
                  props.domainData.trainingDataDisplayCondition.classNames
                }
                sliderRows={2}
                onClickImage={() => ({})}
                onCheckedClassName={(value) => {
                  if (value.length === 0) return
                  props.setTrainingDataDisplayCondition({
                    classNames: value,
                  })
                }}
                changeInferenceResultDisplayCondition={(displayCondition) => {
                  const selectedIds: string[] =
                    displayCondition.selectedLabelIds
                  if (selectedIds.length > 1) {
                    selectedIds.splice(0, 1)
                  }
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { selectedLabelIds, ...rest } = displayCondition
                  props.setAnnotationDisplayCondition({
                    ...rest,
                    selectedIds,
                  })
                }}
                isDisableThumbnailSlider={true}
                isDisableScore={true}
                isDisableSelectButton={true}
                canvasInfoDisplayCondition={{
                  ...props.domainData.canvasInfoDisplayCondition,
                  isDisplayed:
                    props.domainData.annotationsDisplayCondition.selectedIds.at(
                      0
                    ) != null,
                }}
                onChangeCanvasInfoDisplayCondition={
                  onChangeCanvasInfoDisplayCondition
                }
              />
            )}
          </Box>
        </Box>
      ),
    },
    // 処理結果コンテンツ
    {
      label: '詳細',
      displayInfo: (
        <Box component={Paper}>
          <Box p={'24px 32px 32px'} className={classes.detailContent}>
            {detailCanvasInfos.map((canvasInfo, index) => (
              <OnsiteInferenceResultWithImage
                key={index}
                url={
                  props.domainData.currentAnnotationSetDetail.trainingDataList[
                    props.domainData.currentAnnotationSetDetail
                      .selectedTrainingDataFileName
                  ]?.processedUrl ?? ''
                }
                trainedModelName={canvasInfo.label}
                trainedModelVersion={''}
                trainedModelId={''}
                canvasInfo={canvasInfo}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClickLink={() => {}}
                checked={getChecked(
                  checkedAnnotationDataState,
                  props.domainData.currentAnnotationSetDetail.annotationSetId,
                  props.domainData.currentAnnotationSetDetail.annotationResults[
                    props.domainData.currentAnnotationSetDetail
                      .selectedTrainingDataFileName
                  ][index].imageId.toString(),
                  props.domainData.currentAnnotationSetDetail.annotationResults[
                    props.domainData.currentAnnotationSetDetail
                      .selectedTrainingDataFileName
                  ][index].bbox
                )}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClickCard={() => {}}
                onClickCheckbox={() => {
                  updateCheckedAnnotationData(
                    props.domainData.currentAnnotationSetDetail
                      .annotationResults[
                      props.domainData.currentAnnotationSetDetail
                        .selectedTrainingDataFileName
                    ][index].bbox,
                    props.domainData.currentAnnotationSetDetail.annotationSetId,
                    props.domainData.currentAnnotationSetDetail.annotationResults[
                      props.domainData.currentAnnotationSetDetail
                        .selectedTrainingDataFileName
                    ][index].imageId.toString()
                  )
                }}
                isAnnotationSetData={true}
              />
            ))}
          </Box>
        </Box>
      ),
    },
  ]

  return (
    <>
      {!isUndefined(props.domainData.currentAnnotationSetDetail) && (
        <div className={classes.container}>
          <Toast containerOptions={{ limit: 20 }}>
            <Box
              style={{
                position: 'sticky',
                top: '64px',
                backgroundColor: '#fafafa',
                zIndex: 10,
              }}
            >
              <Box className={classes.innerContainer}>
                <Box pt={3}>
                  <BreadcrumbsComponent
                    breadcrumbsPath={[
                      {
                        name: 'データセット一覧',
                        path: 'datasets',
                      },
                      {
                        name: datasetName,
                        path: datasetId,
                      },
                      {
                        name: breadcrumbsAnnotationFileName,
                        path: annotationSetId,
                      },
                    ]}
                  />
                </Box>
                <div className={classes.flexAndBetween}>
                  <Box display='flex' alignItems='center'>
                    <DatasetIcon
                      className={classes.pageIcon}
                      data-testid='DatasetListTitleIcon'
                    />
                    <h2 data-testid='dataset-list-title'>
                      {annotationFileName}
                    </h2>
                  </Box>
                  <Box display='flex'>
                    <Box mr={3}>
                      <CopyableLabel
                        value={annotationSetId}
                        isTooltip
                        placement='top'
                      />
                    </Box>
                  </Box>
                </div>
                <Box p={1}>
                  <div className={classes.flexAndBetween}>
                    <Box display='flex' alignItems='center'>
                      <HandymanIcon
                        style={{ marginRight: '4px' }}
                        sx={{ color: globalTheme.palette.text.secondary }}
                      />
                      <Box sx={{ color: globalTheme.palette.text.secondary }}>
                        <Typography component='div'>
                          <h4>{algorithmDisplayName}</h4>
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' alignItems='center' gap={1}>
                      <Box
                        px={1}
                        sx={{
                          color: globalTheme.palette.primary.contrastText,
                          bgcolor: globalTheme.palette.primary.main,
                        }}
                        borderRadius={1}
                      >
                        {
                          props.domainData.currentAnnotationSetDetail
                            .annotationSetKind
                        }
                      </Box>
                      <Box
                        px={1}
                        sx={{
                          color: globalTheme.palette.primary.contrastText,
                          bgcolor: globalTheme.palette.primary.main,
                        }}
                        borderRadius={1}
                      >
                        {
                          props.domainData.currentAnnotationSetDetail.conditions
                            ?.trainKind
                        }
                      </Box>
                    </Box>
                  </div>
                </Box>
                <Box
                  style={{
                    backgroundColor: '#fafafa',
                  }}
                >
                  <Tabs
                    indicatorColor='primary'
                    value={nowTab}
                    style={{
                      paddingBottom: '16px',
                      marginBottom: '1px',
                    }}
                    onChange={(_, value) => setNowTab(value)}
                  >
                    {tabItems.map((item, index) => (
                      <Tab
                        style={{
                          width: `${100 / tabItems.length}%`,
                          maxWidth: '1200px',
                        }}
                        key={index}
                        className={clsx(nowTab === index && classes.nowTab)}
                        label={item.label}
                        data-testid={`change-tab-${index}`}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
            </Box>
            <Box className={classes.innerContainer}>
              <Paper elevation={0}>
                <Box>{tabItems[nowTab].displayInfo}</Box>
              </Paper>
            </Box>
            {Object.keys(
              props.domainData.currentAnnotationSetDetail.trainingDataList
            ).length > 0 && (
              <ThumbnailSlider
                thumbnails={thumbnailInfos}
                rows={1}
                onClickImage={onClickThumbnail}
                selectedId={selectedImageId}
              />
            )}
          </Toast>
        </div>
      )}
      {fileDataLoading}
      <GlobalLoading
        open={props.appState.isInProgressForGettingAnnotationSetDetail}
      />
    </>
  )
}

export const DatasetDetailAnnotationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DatasetDetailAnnotation))

/**
 * アノテーションセット詳細画面のカスタムフック
 */
const useDatasetDetailAnnotation = (props: Props) => {
  const { classes } = useStyles()

  /** 現在開いているタブ */
  const [nowTab, setNowTab] = useState(0)
  /** データセット名 */
  const [datasetName, setDatasetName] = useState('')
  /** 詳細画面で選択しているアノテーションデータ */
  const [checkedAnnotationDataState, setCheckedAnnotationDataState] = useState<{
    [x: string]: { [x: string]: number[][] }
  }>({})

  /** ローカルストレージに保存されているチェック状態を取得する */
  const getChecked = (
    checkedState: { [x: string]: { [x: string]: number[][] } } | null,
    annotationSetId: string,
    imageId: string,
    box: number[]
  ): boolean => {
    if (checkedState) {
      const checkedBox = checkedState?.[annotationSetId]?.[imageId ?? ''] ?? []

      return checkedBox.findIndex(
        (info) => JSON.stringify(info) === JSON.stringify(box)
      ) < 0
        ? false
        : true
    }
    return false
  }

  /** 画面を開いた際、詳細画面のアノテーションデータのチェックボックスに、ローカルストレージに保存されているチェック状態を設定する */
  useEffect(() => {
    setCheckedAnnotationDataState(
      getCheckedAnnotationData(
        props.auth.customClaims.accountId,
        props.auth.customClaims.userGroupId
      )
    )
  }, [])

  /** 詳細画面のチェックボックスを更新する */
  const updateCheckedAnnotationData = (
    box: number[],
    annotationSetId: string,
    imageId: string
  ) => {
    let value = {}

    const checkedInLocalStorage = getCheckedAnnotationData(
      props.auth.customClaims.accountId,
      props.auth.customClaims.userGroupId
    )

    if (checkedInLocalStorage) {
      const tokenTrainingData = checkedInLocalStorage?.[annotationSetId] ?? {}

      const checkedBox =
        checkedInLocalStorage?.[annotationSetId]?.[imageId] ?? []

      const checkedBoxIndex = checkedBox.findIndex(
        (checked: number[]) => JSON.stringify(checked) === JSON.stringify(box)
      )

      if (checkedBoxIndex !== -1) {
        checkedBox.splice(checkedBoxIndex, 1)
        value = {
          ...checkedInLocalStorage,
          [annotationSetId]: {
            ...tokenTrainingData,
            [imageId]: checkedBox,
          },
        }
      } else {
        value = {
          ...checkedInLocalStorage,
          [annotationSetId]: {
            ...tokenTrainingData,
            [imageId]: [...checkedBox, box],
          },
        }
      }
    } else {
      value = {
        [annotationSetId]: {
          [imageId]: [box],
        },
      }
    }

    try {
      setCheckedAnnotationData(
        props.auth.customClaims.accountId,
        props.auth.customClaims.userGroupId,
        value
      )

      setCheckedAnnotationDataState(value)
    } catch (e) {
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        showErrorToast('MARK できる画像上限数に達しました')
      } else {
        showErrorToast('MARK に失敗しました')
      }
    }
  }

  /** トーストのコンポーネントを取得する */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  /** エラートーストを表示する */
  const showErrorToast = (title: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{title}</div>
      </div>
    )

  /** トースト情報があった場合、表示する */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** パンくずリストに表示するアノテーションファイル名 */
  const breadcrumbsAnnotationFileName = useMemo(() => {
    if (props.appState.annotationSetDetailState.annotationSetState !== 'Loaded')
      return ''
    if (
      props.domainData.currentAnnotationSetDetail.annotationFileName === '' ||
      props.domainData.currentAnnotationSetDetail.annotationFileData == null
    ) {
      return TITLE_FOR_NOT_FOUND_ANNOTATION_FILE
    }
    return props.domainData.currentAnnotationSetDetail.annotationFileName
  }, [
    props.domainData.currentAnnotationSetDetail.annotationFileName,
    props.domainData.currentAnnotationSetDetail.annotationFileData,
    props.appState.annotationSetDetailState.annotationSetState,
  ])

  /** タイトルのアノテーションファイル名 */
  const annotationFileName = useMemo(() => {
    if (props.appState.annotationSetDetailState.annotationSetState !== 'Loaded')
      return ''

    if (
      props.domainData.currentAnnotationSetDetail.annotationFileName === '' ||
      props.domainData.currentAnnotationSetDetail.annotationFileData == null
    ) {
      return `${TITLE_FOR_NOT_FOUND_ANNOTATION_FILE}(${props.domainData.currentAnnotationSetDetail.trainingImageCount}枚)`
    }
    return `${props.domainData.currentAnnotationSetDetail.annotationFileName}(${props.domainData.currentAnnotationSetDetail.trainingImageCount}枚)`
  }, [
    props.domainData.currentAnnotationSetDetail.annotationFileName,
    props.domainData.currentAnnotationSetDetail.annotationFileData,
    props.domainData.currentAnnotationSetDetail.trainingImageCount,
    props.appState.annotationSetDetailState.annotationSetState,
  ])

  /** 表示アルゴリズム名 */
  const algorithmDisplayName = useMemo(
    () =>
      `${props.domainData.currentAnnotationSetDetail.algorithm.metadata.name.ja}`,
    [props.domainData.currentAnnotationSetDetail.algorithm.metadata.name.ja]
  )

  const trainingImageData = useMemo(() => {
    if (
      props.domainData.currentAnnotationSetDetail.annotationFileData?.extensions
        ?.classes &&
      props.domainData.currentAnnotationSetDetail.imageIdFileName != null
    ) {
      // 物体クラス分類の場合のみ（ extensions.classes が存在する場合のみ）
      if (
        props.domainData.currentAnnotationSetDetail.annotationFileData
          .extensions.classes.length !==
        props.domainData.trainingDataDisplayCondition.classNames.length
      ) {
        // 選択中のクラス名とアノテーションファイルに含まれているクラス名が一致しない場合（クラス名による絞り込みが行われている場合）
        // 表示対象のクラス名を抽出
        const displayClasses =
          props.domainData.currentAnnotationSetDetail.annotationFileData.extensions.classes.filter(
            (c: { class_name: string; image_id_list: string[] }) =>
              props.domainData.trainingDataDisplayCondition.classNames.includes(
                c.class_name
              )
          )

        // 表示対象のimage_idを抽出
        const displayImageIds = displayClasses.flatMap((c) => c.image_id_list)

        let hasCurrentSelectedImage = false

        // 表示対象の学習画像のファイル名を抽出
        // 学習画像の一覧から抽出したファイル名のものだけを抽出
        const filteredTrainingData = displayImageIds
          .map((id) => {
            const fileName = props.domainData.currentAnnotationSetDetail
              .imageIdFileName
              ? props.domainData.currentAnnotationSetDetail.imageIdFileName[id]
              : undefined

            if (fileName === undefined) {
              return undefined
            }

            if (
              fileName ===
              props.domainData.currentAnnotationSetDetail
                .selectedTrainingDataFileName
            ) {
              hasCurrentSelectedImage = true
            }

            return {
              fileName: fileName,
              ...props.domainData.currentAnnotationSetDetail.trainingDataList[
                fileName
              ],
            }
          })
          .filter((trainingImage) => trainingImage !== undefined) as {
          id: string
          thumbnailUrl: string
          processedUrl: string
          fileName: string
        }[]

        // クラス名でのフィルタ後に選択中の画像が存在しない場合、フィルタ後の一覧から最初の要素を選択する
        if (!hasCurrentSelectedImage && filteredTrainingData[0]) {
          props.setSelectedImage(filteredTrainingData[0].id)
        }

        return filteredTrainingData
      }
    }

    return Object.keys(
      props.domainData.currentAnnotationSetDetail.trainingDataList
    ).map((fileName: string) => ({
      fileName: fileName,
      ...props.domainData.currentAnnotationSetDetail.trainingDataList[fileName],
    }))
  }, [
    props.domainData.currentAnnotationSetDetail.trainingDataList,
    props.domainData.trainingDataDisplayCondition,
    props.domainData.currentAnnotationSetDetail.imageIdFileName,
    props.domainData.currentAnnotationSetDetail.annotationFileData?.extensions
      ?.classes,
  ])

  /** サムネイル一覧 */
  const thumbnailInfos: ThumbnailInfo[] = useMemo(() => {
    return trainingImageData.map((trainingImage) => ({
      id: trainingImage.id,
      url: trainingImage.thumbnailUrl,
    }))
  }, [trainingImageData])

  /** サムネイルをクリックした時の動作 */
  const onClickThumbnail = useCallback(
    (imageId: string) => {
      props.setSelectedImage(imageId)
    },
    [props.setSelectedImage]
  )

  /** canvas情報 */
  const canvasInfos: CanvasInfo[] = useMemo(
    () =>
      getCanvasInfo({
        annotationResults:
          props.domainData.currentAnnotationSetDetail.annotationResults,
        selectedImageFileName:
          props.domainData.currentAnnotationSetDetail
            .selectedTrainingDataFileName,
        labelId: props.domainData.annotationsDisplayCondition.selectedIds.at(0),
        value: props.domainData.canvasInfoDisplayCondition.displayCount,
        sort: {
          labelName: 'asc',
          area: 'desc',
        },
      }),
    [
      props.domainData.currentAnnotationSetDetail.selectedTrainingDataFileName,
      props.domainData.currentAnnotationSetDetail.trainingDataList,
      props.domainData.currentAnnotationSetDetail.annotationResults,
      props.domainData.annotationsDisplayCondition.selectedIds.at(0),
      props.domainData.canvasInfoDisplayCondition.displayCount,
    ]
  )

  /** canvas情報 */
  const detailCanvasInfos: CanvasInfo[] = useMemo(
    () =>
      getCanvasInfo({
        annotationResults:
          props.domainData.currentAnnotationSetDetail.annotationResults,
        selectedImageFileName:
          props.domainData.currentAnnotationSetDetail
            .selectedTrainingDataFileName,
        sort: {
          labelName: 'asc',
          area: 'desc',
        },
      }),
    [
      props.domainData.currentAnnotationSetDetail.selectedTrainingDataFileName,
      props.domainData.currentAnnotationSetDetail.trainingDataList,
      props.domainData.currentAnnotationSetDetail.annotationResults,
      props.domainData.annotationsDisplayCondition.selectedIds.at(0),
      props.domainData.canvasInfoDisplayCondition.displayCount,
    ]
  )

  /** canvasの表示条件 */
  const inferenceResultDisplayCondition: InferenceResultDisplayCondition =
    useMemo(() => {
      if (
        props.domainData.currentAnnotationSetDetail.annotationFileData != null
      ) {
        const { selectedIds, ...rest } =
          props.domainData.annotationsDisplayCondition
        return {
          ...rest,
          selectedLabelIds: selectedIds,
        }
      }
      return {
        bbox: false,
        label: false,
        mask: false,
        score: undefined,
        selectedLabelIds: [],
        disabled: true,
      }
    }, [
      props.domainData.currentAnnotationSetDetail.annotationFileData,
      props.domainData.annotationsDisplayCondition,
    ])

  /** ファイルデータ取得中のloading */
  const fileDataLoading = useMemo(() => {
    if (props.appState.isInProgressForGettingAnnotationSetDetail) {
      if (nowTab === 0) {
        return (
          <Box className={classes.fileDataLoading}>
            <CircularProgress size={64} />
          </Box>
        )
      }
      return <></>
    }
    return <></>
  }, [props.appState.isInProgressForGettingAnnotationSetDetail, nowTab])

  /**
   * 表示するボックス数の初期値と最大値を設定する
   * - 最大値はアノテーション数
   * - 表示数のデフォルトは 5
   * - アノテーション数が5以下の場合の場合は表示数 == アノテーション数
   */
  useEffect(() => {
    if (
      Object.keys(
        props.domainData.currentAnnotationSetDetail.annotationResults
      ).findIndex(
        (key) =>
          key ===
          props.domainData.currentAnnotationSetDetail
            .selectedTrainingDataFileName
      ) !== -1 &&
      props.domainData.annotationsDisplayCondition.selectedIds.at(0) != null
    ) {
      const detectedCount =
        props.domainData.currentAnnotationSetDetail.annotationResults[
          props.domainData.currentAnnotationSetDetail
            .selectedTrainingDataFileName
        ].filter(
          (item) =>
            item.label.id ===
            props.domainData.annotationsDisplayCondition.selectedIds.at(0)
        ).length

      const defaultValue = detectedCount < 5 ? detectedCount : 5
      props.setCanvasInfoDisplayCondition({
        totalCount: detectedCount,
        displayCount: String(defaultValue),
      })
    }
  }, [
    props.domainData.currentAnnotationSetDetail.annotationResults,
    props.domainData.currentAnnotationSetDetail.selectedTrainingDataFileName,
    props.domainData.annotationsDisplayCondition.selectedIds.at(0),
  ])

  /** キャンバスに描画する要素数を変更した時の動作 */
  const onChangeCanvasInfoDisplayCondition: (value: string) => void =
    useCallback(
      (value) => {
        props.setCanvasInfoDisplayCondition({
          ...props.domainData.canvasInfoDisplayCondition,
          displayCount: value,
          isDisplayed:
            props.domainData.annotationsDisplayCondition.selectedIds.at(0) !=
            null,
        })
      },
      [
        props.domainData.annotationsDisplayCondition.selectedIds.at(0),
        props.domainData.canvasInfoDisplayCondition,
      ]
    )

  const selectedImageId = useMemo(() => {
    if (
      Object.keys(props.domainData.currentAnnotationSetDetail.trainingDataList)
        .length > 0 &&
      props.domainData.currentAnnotationSetDetail.selectedTrainingDataFileName
    ) {
      return props.domainData.currentAnnotationSetDetail.trainingDataList[
        props.domainData.currentAnnotationSetDetail.selectedTrainingDataFileName
      ]?.id
    }

    return ''
  }, [
    props.domainData.currentAnnotationSetDetail.selectedTrainingDataFileName,
    props.domainData.currentAnnotationSetDetail.trainingDataList,
  ])

  return {
    trainingImageData,
    annotationFileName,
    algorithmDisplayName,
    breadcrumbsAnnotationFileName,
    canvasInfos,
    checkedAnnotationDataState,
    datasetName,
    detailCanvasInfos,
    fileDataLoading,
    inferenceResultDisplayCondition,
    nowTab,
    selectedImageId,
    thumbnailInfos,
    onChangeCanvasInfoDisplayCondition,
    onClickThumbnail,
    getChecked,
    setDatasetName,
    setNowTab,
    updateCheckedAnnotationData,
  }
}

interface FormatAnnotationResult {
  annotationResults: AnnotationResults[]
  labelId?: string
  value?: string
  sort?: {
    labelName: 'asc' | 'desc'
    area: 'asc' | 'desc'
  }
}
/**
 * AnnotationResultのフォーマットを行う
 * - ラベルIDと範囲を指定して表示する情報を絞り込む
 * - ラベル名とareaの数値でソートする
 */
const formatAnnotationResult = (
  props: FormatAnnotationResult
): AnnotationResults[] => {
  const {
    annotationResults,
    labelId,
    value,
    sort = { labelName: 'asc', area: 'desc' },
  } = props

  const selectedLabelAnnotationResultIndex = annotationResults.findIndex(
    (item) => item.label.id === labelId
  )

  const newSelectedLabelAnnotationResult = annotationResults.filter(
    (item) => item.label.id !== labelId
  )

  const selectedLabelAnnotationResult = annotationResults
    .filter((item) => item.label.id === labelId)
    .sort((a, b) => (sort.area === 'asc' ? a.area - b.area : b.area - a.area))

  let count = 0
  const newSelectedAnnotationResult =
    value != null
      ? selectedLabelAnnotationResult.filter((item) => {
          if (item.label.id !== labelId) return true
          if (count < Number(value)) {
            count += 1
            return true
          }
          count += 1
          return false
        })
      : selectedLabelAnnotationResult

  newSelectedLabelAnnotationResult.splice(
    selectedLabelAnnotationResultIndex,
    0,
    ...newSelectedAnnotationResult
  )

  const sortedAnnotationResults = sortAnnotationResults({
    annotationResults: newSelectedLabelAnnotationResult,
    sort,
  })
  return sortedAnnotationResults
}

interface GetCanvasInfo {
  selectedImageFileName: string
  annotationResults: { [key: string]: AnnotationResults[] }
  labelId?: string
  value?: string
  sort?: {
    labelName: 'asc' | 'desc'
    area: 'asc' | 'desc'
  }
}
/**
 * canvasInfoを取得する
 */
const getCanvasInfo = (props: GetCanvasInfo) => {
  const {
    annotationResults,
    value,
    labelId,
    sort = {
      area: 'desc',
      labelName: 'asc',
    },
  } = props

  if (
    props.selectedImageFileName === '' ||
    Object.keys(annotationResults).indexOf(props.selectedImageFileName) === -1
  ) {
    return []
  }

  // 選択中の画像のアノテーションデータ
  const selectedAnnotationResult =
    annotationResults[props.selectedImageFileName]

  // 色の取得
  const uniqueIds = Array.from(
    new Set(selectedAnnotationResult.map((result) => result.label.id))
  )
  let idColorMapping: { [x: string]: string } = {}
  uniqueIds.forEach((id) => {
    idColorMapping = {
      ...idColorMapping,
      [`${id}`]: createColor(id).hex(),
    }
  })

  const newSelectedLabelAnnotationResult = formatAnnotationResult({
    annotationResults: selectedAnnotationResult,
    labelId,
    value,
    sort,
  })

  return newSelectedLabelAnnotationResult.map((selectedImage) => ({
    id: selectedImage.label.id,
    color: idColorMapping[selectedImage.label.id],
    score: 0.5,
    label: selectedImage.label.name,
    x: selectedImage.bbox[0],
    y: selectedImage.bbox[1],
    width: selectedImage.bbox[2],
    height: selectedImage.bbox[3],
    mask: selectedImage.segmentation.mask,
  }))
}

interface GetCategoryIdList {
  annotationResults: AnnotationResults[]
}
/**
 * AnnotationResultsからラベルIDを抽出する
 */
const getLabelIdList = (props: GetCategoryIdList) => {
  return Array.from(
    new Set(props.annotationResults.map((item) => item.label.id))
  )
}

interface SortAnnotationResults {
  annotationResults: AnnotationResults[]
  sort: {
    labelName: 'asc' | 'desc'
    area: 'asc' | 'desc'
  }
}
/**
 * AnnotationResultsを並び替えた新たな配列を生成する
 * - 第1ソートキー：label.name
 * - 第2ソートキー：area
 */
const sortAnnotationResults = (
  props: SortAnnotationResults
): AnnotationResults[] => {
  const { annotationResults, sort } = props
  const targetAnnotationResults = annotationResults.sort((a, b) =>
    sort?.labelName === 'asc'
      ? a.label.name > b.label.name
        ? 1
        : -1
      : a.label.name > b.label.name
      ? -1
      : 1
  )
  const labelIdList = getLabelIdList({
    annotationResults: targetAnnotationResults,
  })
  const sortedAnnotationResults: AnnotationResults[] = []
  labelIdList.map((targetLabelId) => {
    const sortedData = targetAnnotationResults
      .filter((item) => item.label.id === targetLabelId)
      .sort((a, b) => (sort.area === 'asc' ? a.area - b.area : b.area - a.area))
    sortedAnnotationResults.push(...sortedData)
  })

  return sortedAnnotationResults
}
